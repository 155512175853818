/* Fonts */ 
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap');

/* Tailwind */ 
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Vendors */ 
/*@import "./aos.css";*/ 
@import "./ripple-effect.css";

/* Custom Styles */ 
@layer base {} 
@layer components {}
@layer utilities {}
